import './index.css';

import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { enableMapSet } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import Loader from './components/primitives/Loader';
import reportWebVitals from './reportWebVitals';
import { isDevelopment, isStaging } from './utils/utils';

enableMapSet();

if (!isDevelopment()) {
  Sentry.init({
    dsn: 'https://ad67063c1c956f1724d232f99bd86d1f@o4505950121164800.ingest.sentry.io/4505950124900352',
    environment: isStaging() ? 'staging' : 'prod',
    maxValueLength: 1_000_000,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
      new CaptureConsole({ levels: ['error'] }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/app\.staging\.warden\.global/,
      /^https:\/\/app\.warden\.global/,
      /^https:\/\/pro\.staging\.warden\.global/,
      /^https:\/\/pro\.warden\.global/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const App = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (import.meta.env.VITE_IS_IN_E2E === 'true') {
  root.render(
    <React.Suspense fallback={<Loader />}>
      <App />
    </React.Suspense>
  );
} else {
  root.render(
    <React.StrictMode>
      <React.Suspense fallback={<Loader />}>
        <App />
      </React.Suspense>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
